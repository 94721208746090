.intschl-container{
    width: 100%;
    padding: 10px 50px;
    margin: 100px 0px 0px 0px;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}

.intschl-admin-box1{
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: space-around;
}

.intschl-admin-box1{
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: space-around;
}

.intschl-box-profile{
    display: grid;
    justify-content: center;
}

.intschl-profile-edit{
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-end;
    justify-content: flex-end;
    padding: 10px 0px;
}

.intschl-profile-edit button{
    background-color: #2dd872;
    padding: 10px;
    text-transform: uppercase;
    outline: none;
    border: none;
    border-radius: 4px;
}

.intschl-profile-edit button a{
    text-decoration: none;
    color: rgb(18, 18, 18);
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    
}

.intschl-profile-edit button a i{
    margin-left: 8px;
    font-size: 1.2rem;
}


.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.modal-open {
    display: flex;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 450px; 
    text-align: center;
}

.school-list {
    list-style: none;
    padding: 0;
    margin: 10px; 
    text-align: left;
    margin-left: 10%;
}

.school-list li {
    padding: 10px;
    cursor: pointer;
}

.school-list li:hover {
    background: #f0f0f0;
}

.modal-content button {
    margin-left: 80%;
    font-weight: 500;
    cursor: pointer;
    margin-top: 20px;
    padding: 5px 15px; 
    border-radius: 5px;
    color: rgb(255, 0, 17);
    background: #e9e7e7;
    border: 1px solid rgb(0, 0, 0); 
}

.modal-content button:hover {
    background: #d4d4d4; 
    
}


.branch-info {
    margin: 20px 0;
}

.branch-info h4 {
    font-size: 1rem;
    margin-bottom: 10px;
}

.branch-info table {
    width: 120%;
    border-collapse: collapse;
    background-color: #eaeaea;
}

.branch-info th,
.branch-info td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    
}

.branch-info th {
    background-color: #bdbdbd;
}


@media (max-width: 990px) {
    .intschl-container {
        padding: 10px 30px; 
        margin-top: 130px; 
    }

    .intschl-admin-box1 {
        flex-direction: column;
        align-items: center;
    }

    .intschl-profile-edit {
        justify-content: right;
        padding: 10px 0;  
    }

    .modal-content {
        width: 90%; 
        max-width: 400px; 
    }

    .school-list {
        margin-left: 0; 
    }

    .branch-info {
        overflow-x: auto; 
    }

    .branch-info table {
        display: block; 
        width: 220%; 
    }

    .branch-info th, .branch-info td {
        display: inline-block; 
        width: 20%; 
        text-align: left;
    }

    .branch-info th {
        background-color: #bdbdbd;
        font-size: 14px;
    }

    .branch-info td {
        padding: 10px 5px; 
    }
}