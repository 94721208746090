html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
    background-color: #f4f4f4;
    letter-spacing: 1.2px;
}

.search-container {
    text-align: center;
    margin-top: 50px;
    width: 100%;
   
}

.search-container input[type="text"]::placeholder{
    font-family: "Noto Sans", sans-serif;
    font-size: 0.8rem;
    text-transform: uppercase;
}

.search-container input[type="text"] {
    width: 30%;
    padding: 10px;
    margin-right: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-container button {
    padding: 9.5px 20px;
    border: 1px solid  #ffad31;
    background-color: #ffad31;
    color: #fefefe;
    border-radius: 4px;
    cursor: pointer;
    font-weight: lighter;
}

.advertisement {
    text-align: center;
    margin: 50px 50px;
}

.advertisement-small {
    width: 100%;
    height: 650px;
    margin-bottom: 50px;
    background-size: cover;
    background-color: #007bff00;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    
}
.advertisement-small img{
    border-radius: 10px;
}

.advertisement-large img{
    border-radius: 10px;
}
.categories-ad-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    
}

.categories {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: -400px;
    margin-left: 25px;
}

.categories.right, .categories.left {
    width: 25%;
}

.advertisement-large {
    margin-top: 50px;
    width: 60%;
    height: 120vh;
    margin-bottom: 50px;
    background-size: cover;
    margin-left: 10px;
    margin-right: 0;
}

.category {
    padding: 15px;
    height: 50px;
    width: 70%;
    border: none;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bolder;
    text-transform: uppercase;
    margin: 15px 50px;
    text-align: center;
    letter-spacing: 0.5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.category:hover{
    color: #fff;
    text-decoration: none;
}

.category.blue { background-color: #3636de; }
.category.red { background-color: #f02e2e; }
.category.green { background-color: #30e030; }
.category.yellow { background-color: #d1b61f; }
.category.purple { background-color: #800080; }
.category.pink { background-color: #e228e2; }
.category.orange { background-color: #ffa600; }
.category.green-dark { background-color: #006400; }
.category.blue-light { background-color: #4da9c8; }
.category.pink-dark { background-color: #ff1493; }

.courses {
    text-align: center;
    margin: 50px 0;
}

.courses h2 {
    font-size: 20px;
    margin-bottom: -20px;
}

.course-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.course-item {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    width: 300px;
    text-align: left;
    height: 270px;
}

.date {
    width: 250px;
    text-align: center;
    background-color: #9ac7f6;
    padding: 10px 0;
    margin-right: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #0c59aa;
    margin-bottom: 20px;
}

.date-top {
    padding: 6px 0;
    background-color: #0c59aa;
    width: 250px;
}

.course-item hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 15px 0;
}

.course-title a {
    text-decoration: none;
    color: #007BFF;
    font-size: 18px;
}

.course-title a:hover {
    color: #000;
}

.course-details {
    font-size: 14px;
    color: #555;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 20px;
}

.course-ins {
    font-size: 12px;
    color: #7d7c7c;
    margin-top: 10px;
    font-weight: bold;
}

.course-ins a {
    font-size: 12px;
    color: #555;
}

.see-all-courses {
    display: inline-block;
    font-size: 12px;
    margin: 30px 0;
    padding: 10px;
    border: 1px solid #007BFF;
    background-color: #007BFF;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    margin-top: -10px;
    margin-bottom: 50px;
}

.see-all-courses:hover {
    background-color: #4f9cee;
    color: #fff;
    text-decoration: none;
}


.categories .left-ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    list-style: none;
    gap: 35px;
}
.categories .left-ul li {
    padding: 10px 15px;
    border-radius: 2px;
    text-align: center;
}
.categories .left-ul li a {
    font-size: 0.9rem;
    color: #efeff3;
    font-weight: bold;
}

/* General styles for dropdown containers */
#d0, #d, #d1, #d2, #d3, #d4, #d5, #d6{
    position: relative;
    display: inline-block;
}

#d0 a, #d a, #d1 a, #d2 a, #d3 a, #d4 a, #d5 a , #d6 a{
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 4px;
    height: 50px;
}

.dd0, .dd, .dd1, .dd2, .dd3, .dd4, .dd5, .dd6 {
    display: none;
    position: absolute;
    width: 252px;
    z-index: 1;
    border-radius: 4px;
    padding: 10px 0;
    top: 60px;
    left: 3.1rem;
}

#d0:hover .dd0, #d:hover .dd, #d1:hover .dd1, #d2:hover .dd2, 
#d3:hover .dd3, #d4:hover .dd4, #d5:hover .dd5, #d6:hover .dd6 {
    display: block;
}

.dd0 a, .dd a, .dd1 a, .dd2 a, .dd3 a, .dd4 a, .dd5 a, .dd6 a {
    color: white;
    text-decoration: none;
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight:600;
    border-top: 1px solid #fff;
}

.dd0 a:hover, .dd a:hover, .dd1 a:hover, .dd2 a:hover, 
.dd3 a:hover, .dd4 a:hover, .dd5 a:hover , .dd6 a:hover{
    color: #fff;
}

#d0 a, .dd0 { background-color: #0c6a03; }
#d a, .dd { background-color: #30e030; }
#d1 a, .dd1 { background-color: #3039e0; }
#d2 a, .dd2 { background-color: #ff1493; }
#d3 a, .dd3 { background-color: #fb2cc0; }
#d4 a, .dd4 { background-color: #3a7eea; }
#d5 a, .dd5 { background-color: #30e085; }
#d6 a, .dd6 { background-color: #e03030; }


#d0a i, #d a i, #d1 a i, #d2 a i, #d3 a i, #d4 a i, #d5 a i, #d6 a i {
    margin-left: 10px;
}



/* Media Queries for Responsive Design */
@media screen and (max-width: 1140px) {
    .search-container input[type="text"] {
        width: 70%;
        margin-bottom: 10px;
    }

    .categories-ad-container {
        grid-template-columns: 1fr;
    }

    .categories.left {
        width: 47%;
    }

    .categories.right {
        width: 47%;
    }

    #d0, #d, #d1, #d2, #d3, #d4, #d5, #d6 {
        width: 100%;
    }

    .dd0, .dd, .dd1, .dd2, .dd3, .dd4, .dd5, .dd6 {
        height: 130%;
        top: 50px;
        left: 21.5%;
        width: 100%;
       font-size: 10px;
    }

    .dd0 a, .dd a, .dd1 a, .dd2 a, .dd3 a, .dd4 a, .dd5 a, .dd6 a {
        font-size: 11px;
    }


    .advertisement-small {
        width: 100%;
        height: 600px;
        margin-bottom: 50px;

    }
    .advertisement-small img{
        border-radius: 15px;
    }
    
    .advertisement-large img{
        border-radius: 15px;
    }
    
}

@media screen and (max-width: 768px) {
    .search-container input[type="text"] {
        width: 70%;
    }

    .search-container button {
        width: 15%;
        margin-top: 10px;
    }

    .categories {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        margin-top: 0;
    }

    .categories.left {
        width: 43%;
        margin-left: 0px;
    }

   .categories.right {
        width: 43%;
        margin-right: 70px;
        margin-left: -30px;
    }

    .categories.left .category, .categories.right .category {
        width: 100%;
        font-size: 12px;
        letter-spacing: 0.5px;
    }

    .advertisement-large {
        height: 0px;
        
    }

    .advertisement-small {
        height: 280px; 
        width: 100%;
        margin-top: 40px;
    }

    .advertisement-small img{
        border-radius: 10px;
    }
    
    .advertisement-large img{
        border-radius: 10px;
    }

    .advertisement {
        text-align: center;
        margin: 10px 10px;
    }
  
    .category {
        width: 100%;
        margin: 10px 35px;
    }

    .course-item {
        width: 90%;
        margin-left: 15px;
    }

    .date {
        width: 90%;
        font-size: 18px;
    }

    .date-top {
        width: 90%;
    }
    
}

