

.event-details {
  margin-bottom: 40px;
  padding: 65px;
  margin-left: 100px;
  letter-spacing: 1.2px;
  line-height: 30px;
}

.event-details h1 {
  margin-bottom: 50px;
  background-color: #97badf;
  padding: 12px;
}

.event-img {
  width: auto;
  height: auto;
  margin-top: 20px;
  background-size: cover;
  background-position: center;
  margin-left: 15px;
}

.event-date {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 50px;
}

.date-month {
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: bolder;
  background-color: #0567cf;
  width: 70px;
  padding: 5px;
  margin-right: 20px;
  margin-top: -15px;
}

.date-day {
  font-size: 30px;
  width: 70px;
  text-align: center;
  background-color: #5a5b5c;
  padding: 10px 0;
  margin-right: 10px;
  color: #ffffff;
}

.event-info h2 {
  margin: 0;
}

.event-info p1 {
  margin: 5px 0;
  font-weight: bolder;
}

.event-info p {
  margin: 5px 0;
}

.event-details p {
  margin: 10px 0;
}

.event-details ul {
  list-style-type: disc;
  margin: 10px 0;
  padding-left: 40px;
  margin-left: 50px;
}

.event-details h1 {
  margin: 20px 0 10px;
  margin-top: 50px;
}

.event-details h3 {
  margin: 20px 0 10px;
  color: #000000;
}

button[type="cbutton"] {
  background-color: #ff8800; 
  color: white;
  border-radius: 8px;
  padding: 12px 12px; 
  font-size: 15px; 
  cursor: pointer;
  transition: background-color 0.3s ease;
  letter-spacing: 1px;
  border-color: #dddddd;
 
}

button[type="cbutton"]:hover {
  background-color: #f9b938; 
}



@media (max-width: 768px) {
  .event-details {
      padding: 20px;
      margin-left: 0;
      line-height: 1.5;
  }

  .event-details h1 {
      margin-bottom: 30px;
      font-size: 1.5rem;
      padding: 10px;
  }

  .event-img {
      width: 100%;
      height: auto;
      margin-left: 0;
  }

  .event-date {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;
  }

  .date-month, .date-day {
      width: 120%;
      margin-right: 0;
      margin-bottom: 10px;
  }

  .date-month {
      padding: 5px 10px;
  }

  .date-day {
      padding: 5px 10px;
  }

  .event-info h2 {
      font-size: 1.2rem;
  }

  .event-details ul {
      padding-left: 20px;
      margin-left: 0;
  }
}


