.AllViews-admin{
    width: 100%;
}

#admin-dashboard {
    width: 100%;
    height: auto;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}

.nav-bar-admin {
    background-color: #0e0534;
    color: white;
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: row;
    width: 100%;
    z-index: 6677;
    border:2px solid #ffffff ;
}
.menu{
    margin: 0px 0px 0px 20px;
}
 .search-icon-admin input[type="text"] {
    width: 240px;
    height: 40px;
    border: none;
    border-radius: 4px 0px 0px 4px;
    outline: none;
    padding: 10px;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}

.search-icon-admin input[type="submit"] {
    background-color: #f6923a;
    color: white;
    border: none;
    border-radius: 0px 4px 4px 0px;
    height: 40px;
    padding: 10px 15px;
    cursor: pointer;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
} 


.side-nav-admin {
    width: 300px;
    height: 100%;
    background-color: #0e0534;
    display: none;
    justify-content: center;
    align-items: center;
    justify-items: center;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    position: fixed;
    color: #ffffff;
    top: 0;
    z-index: 1;
    border: 2px solid #ffffff;
    overflow-y: auto; 
}



.side-nav-admin::-webkit-scrollbar {
    width: 8px;
}

.side-nav-admin::-webkit-scrollbar-thumb {
    background-color: #979797;
    border-radius: 10px;
}

.side-nav-admin::-webkit-scrollbar-track {
    background-color: #333;
}


.side-open {
    display: grid;
}

.logo-section-admin {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    text-transform: uppercase;
    font-size: 0.9rem;
}

.menu-logo-admin {
    border: #ffffff 1px solid;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
}

.text-admin-head {
    width: 100%;
    font-size: 1rem;
    color: #ffffff;
  
}

.menu-list-admin ul {
    display: grid;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    list-style: none;
    
    
}
.menu-list-admin ul {
    margin-bottom: 80%; 
    margin-top: 50%;
}
.menu-list-admin ul li {
    padding: 12px 0px;
    
}

.menu-list-admin ul li h4{
    margin-top: 60px;
}

.menu-list-admin ul li a {
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    text-transform: uppercase;
    font-size: 0.9rem;
    text-align: start;
}

.menu-list-admin ul li a .fa-building,
.menu-list-admin ul li a .fa-house,
.menu-list-admin ul li a .fa-user,
.menu-list-admin ul li a .fa-book,
.menu-list-admin ul li a .fa-calendar-days,
.menu-list-admin ul li a .fa-graduation-cap,
.menu-list-admin ul li a .fa-gift,
.menu-list-admin ul li a .fa-list-alt,
.menu-list-admin ul li a .fa-certificate,
.menu-list-admin ul li a .fa-laptop-file,
.menu-list-admin ul li a .fa-users{
    margin-right: 20px;
    padding: 10px;
    background-color: #f6923a;
    border-radius: 2px;
}

.menu-list-admin{
    display: grid;
    align-items: center;
    justify-content: center;
    
}
.solid-icon-admin {
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-items: flex-start;
}

.solid-icon-admin:hover{
    background-color: #f6923a;
    color: white;
    transform: scale(1.2);
    transition: 600ms;
}

.fa-sort-down {
    font-size: 1.2rem;
    margin: 0px 0px 10px 10px;
}

.menu-list-log-in-admin {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
   
}

.menu-list-log-in-admin ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
}

.solid-icon-admin-profile i {
    background-color: #363638;
    font-size: 1.1rem;
    padding: 12px;
    color: thistle;
    border-radius: 50%;
}

.solid-icon-admin-logout {
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 20px;
}

.solid-icon-admin-logout a {
    background-color: rgb(240, 60, 60);
    color: #ffffff;
    padding: 8px;
    border-radius: 4px;
}
#blue-admin{
    background-color: #5336d5;
    color: #ffffff;
    padding: 4px;
    border-radius: 4px;
    text-decoration: none;
}

#drop-admin-1 {
    position: relative;
    display: inline-block;
}


#drop-admin-1 {
    position: relative;
    display: inline-block;
}

#drop-admin-1 a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-content4-admin {
    height: auto;
    position: absolute;
    top: 50px;
    left: 0%;
    width: 100%;
    display: none;
    gap: 0px;
    padding: 0px;
    background-color: #f6923a;
    border-radius: 4px;
    z-index: 9999999;
}

.dropdown-content4-admin a {
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: bold;
    padding: 10px 15px;
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.dropdown-content4-admin a:hover {
    background-color: #e3791d;
}

#drop-admin-1:hover .dropdown-content4-admin {
    display: grid;
}


#drop-admin-6 {
    position: relative;
    display: inline-block;
}

#drop-admin-6 a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-content6-admin {
    height: auto;
    position: absolute;
    top: 50px;
    left: 0%;
    width: 100%;
    display: none;
    gap: 0px;
    padding: 0px;
    background-color: #f6923a;
    border-radius: 4px;
    z-index: 9999999;
}

.dropdown-content6-admin a {
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: bold;
    padding: 10px 15px;
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.dropdown-content6-admin a:hover {
    background-color: #e3791d;
}

#drop-admin-6:hover .dropdown-content6-admin {
    display: grid;
}


#drop-admin-7 {
    position: relative;
    display: inline-block;
}

#drop-admin-7 a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-content7-admin {
    height: auto;
    position: absolute;
    top: 50px;
    left: 0%;
    width: 100%;
    display: none;
    gap: 0px;
    padding: 0px;
    background-color: #f6923a;
    border-radius: 4px;
    z-index: 9999999;
}

.dropdown-content7-admin a {
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: bold;
    padding: 10px 15px;
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.dropdown-content7-admin a:hover {
    background-color: #e3791d;
}

#drop-admin-7:hover .dropdown-content6-admin {
    display: grid;
} 


#drop-admin-7 {
    position: relative;
    display: inline-block;
}

#drop-admin-7 a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-content7-admin {
    height: auto;
    position: absolute;
    top: -20px;
    left: 0%;
    width: 100%;
    display: none;
    gap: 0px;
    padding: 0px;
    background-color: #f6923a;
    border-radius: 4px;
    z-index: 9999999;
}

.dropdown-content7-admin a {
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: bold;
    padding: 10px 15px;
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.dropdown-content7-admin a:hover {
    background-color: #e3791d;
}

#drop-admin-7:hover .dropdown-content7-admin {
    display: grid;
}



#drop-admin-2 {
    position: relative;
    display: inline-block;
}

#drop-admin-2 a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-content5-admin {
    height: auto;
    position: absolute;
    top: 50px;
    left: 0%;
    width: 100%;
    display: none;
    gap: 0px;
    padding: 0px;
    background-color: #f6923a;
    border-radius: 4px;
    z-index: 9999999;
}

.dropdown-content5-admin a {
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: bold;
    padding: 10px 15px;
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.dropdown-content5-admin a:hover {
    background-color: #e3791d;
}

#drop-admin-2:hover .dropdown-content5-admin {
    display: grid;
}

.admin-btn {
    background-color: #ff5500;
    color: white; 
    border: none; 
    padding: 10px 20px; 
    border-radius: 5px; 
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase; 
    width: 100%; 
    text-align: left;
    letter-spacing: 1.5px;
}

.admin-btn:hover {
    background-color: #d95c02; 
    color: #f8f9fa; 
}




.dropdown-content5-admin .admin-btn {
    margin-bottom: 10px; 
    display: block; 
}


@media (max-width: 730px) {
    .nav-bar-admin {
        display: grid;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 100%;
    }

    .logo-section-admin {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    
}
