.sa-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}

.sa-head-content-text {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    color: #181616;
    background-color: #e0e0e6;
    text-align: center;
    margin-top: 5%;
    padding: 20px 10px;
    text-transform: uppercase;
}

.new{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}


.sa-container form {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 50px 0px;
    border-radius: 2px;
    background-color: #e0e0e6;
}


.sa-container form textarea {
    width: 100%;
    padding: 40px;
    border: 1px solid #e0e0e6;
    border-radius: 4px;
    outline: none;
}

.sa-container form  button {
    font-family: "Noto Sans", sans-serif;
    background-color: #242321;
    color: #ffffff;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 30%;
    margin: 20px auto 0 auto;
    font-size: 18px;
}

.sa-container form button:hover {
    background-color: #2e2e2e;
}

.sa-container form .half {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
}

.formgroup {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f6923a;
    padding: 10px;
    border-radius: 4px;
    margin: 12px 0px;
}

.add-half{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
    margin-left: 20px;
}

.formgroup input, .formgroup select {
    width: 100%;
    padding: 12px;
    border: none;
    outline: none;
    border-radius: 4px;
}

.formgroup i {
    font-size: 1.1rem;
    color: #1b1818;
    margin-right: 10px;
}


/* Category button */
.formgroup .categorybutton{
    background-color: #4CAF50; 
    color: white; 
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 0px;
    margin-left: 12px;
    transition: background-color 0.3s ease;
}

.formgroup .categorybutton:hover {
    background-color: #45a049; 
}

/* Modal background overlay */
.formgroup .cmodal {
    display: flex;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

/* Modal content box */
.formgroup .cmodal-content {
    background-color: #fff;
    padding: 30px 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: fadeIn 0.3s ease;
}

/* Input field for new category */
.formgroup .cmodal-content input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}


/* Modal buttons styling */
.formgroup .cmodal-content button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

/* Add Category button */
.formgroup .cmodal-content button:first-child {
    background-color: #3475cf;
    color: white;
    margin-right: 20px; 
    padding: 5px;
    width: 45%;
}

.formgroup .cmodal-content button:first-child:hover {
    background-color: #2c63b0;
}

/* Cancel button */
.formgroup .cmodal-content button:last-child {
    background-color: #f44336;
    color: white;
    padding: 5px;
    margin-left: 20px;
   
}

.formgroup .cmodal-content button:last-child:hover {
    background-color: #d32f2f;
}


@media(max-width:990px){
    .sa-container  {
        width: 100%;
        padding: 20px;
        
    }
    .sa-container  form {
        width: 100%;
        padding: 15px;
    }

    .sa-container form button {
        width: 50%;
    }
}