
.content-local-job {
  width: 100%;
  height: auto;
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
}

.local-job-view-1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5% 10%;
}

.local-row01 {
  width: 600px;
  height: auto;
}

#test-head {
  font-size: 1.4rem;
  font-weight: 800;
}

#test-head-post {
  font-size: 0.8rem;
  text-transform: uppercase;
}

.text-marge {
  margin: 5px 0px 0px 32px;
}

#text-seen {
  text-transform: uppercase;
  font-weight: 800;
}

.test-loacl-details {
  margin-top: 40px;
}

#send-marge-local {
  margin: 0px 0px 10px 0px;
}

.contect-wrap-1-local {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0px;
}

.contect-1-local {
  margin: 10px 0px;
}

.applyConLocal {
  width: 100%;
  
}

.applyConLocal {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding: 5% 10%;
}

#button-up {
  margin: 20px 0px;
  padding: 8px;
  background-color: #0e0534;
  font-size: 1rem;
  border-radius: 4px;
  color: aliceblue;
}

.applyConLocal h2,
.applyConLocal1 h2 {
  text-transform: uppercase;
}

#local-app {
  height: 4px;
  background-color: #0e0534;
}


@media (max-width: 1500px) {
  .local-job-view-1 {
    flex-direction: column;
    padding: 5% 5%;
    margin: 20px 5px;
  }

  .local-row01 {
    width: 100%;
    height: auto;
    margin-top: 30px;
  }

  .contect-wrap-1-local {
    flex-direction: column;
    align-items: flex-start;
  }

  #button-up {
    font-size: 0.9rem;
    padding: 10px;
    width: 100%;
  }
}


@media (max-width: 768px) {
  .local-job-view-1 {
    flex-direction: column;
    padding: 5% 3%;
    margin: 20px 5px;
  }

  .local-row01 {
    width: 100%;
    height: auto;
    margin-top: 30px;
  }

  .image-in-local img {
    width: 100%;
    height: auto;
  }

  #test-head {
    font-size: 1.2rem;
  }

  #test-head-post {
    font-size: 0.7rem;
  }

  .text-marge {
    margin-left: 10px;
  }

  .applyConLocal {
    padding: 10px;
    height: auto;
  }

  .contect-wrap-1-local {
    flex-direction: column;
    align-items: flex-start;
  }

  #button-up {
    font-size: 0.85rem;
    padding: 10px;
    width: 100%;
  }

  .test-loacl-details {
    margin-top: 20px;
  }
}



@media (max-width: 480px) {
  .local-job-view-1 {
    padding: 5% 2%;
  }

  .local-row01 {
    width: 100%;
    height: auto;
    margin-top: 30px;
  }

  #test-head {
    font-size: 1.2rem;
  }

  .image-in-local img {
    width: 100%;
    height: auto;
  }

  .contect-wrap-1-local {
    width: 100%;
  }

  .contect-1-local {
    width: 100%;
    margin-bottom: 15px;
  }

  #button-up {
    font-size: 0.75rem;
    padding: 8px;
    width: 100%;
  }
}
.applyConLocal1{
  padding: 5% 10%;
}
.applyConLocal1  form{
  width:800px;
  

}
.first-fjob-1{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
  
}

.first-fjob-1 input{
  width: 100%;
  height: 45px;
  margin: 0px 10px 0 0;
  padding: 8px;
  border-radius: 3px;
  outline: none;
  border: 1px solid #171717a9;
}

.first-fjob-1 textarea{
  width: 100%;
  height: 150px;
  margin: 0px 10px 0 0;
  padding: 10px;
}



@media (max-width: 768px) {
  .applyConLocal1 form {
    width: 100%; 
    padding: 0%;
  }

  .first-fjob-1 {
    flex-direction: column;
    justify-content: center; 
    align-items: flex-start;
  }

  .first-fjob-1 input,
  .first-fjob-1 textarea {
    width: 100%; 
    margin: 10px 0; 
  }

  .local-job-view-1 {
    padding: 5% 3%;
  }

  #button-up {
    font-size: 0.85rem;
    padding: 10px;
    width: 100%;
  }

  .test-loacl-details {
    margin-top: 20px;
  }

  .applyConLocal {
    padding: 10px;
    height: auto;
  }

  #test-head {
    font-size: 1.2rem;
  }
}


@media (max-width: 480px) {
  .applyConLocal1 form {
    width: 100%; 
    padding: 0%;
   
  }

  .first-fjob-1 {
    flex-direction: column; 
    align-items: flex-start;
    width: 100%;
  }

  .first-fjob-1 input,
  .first-fjob-1 textarea {
    width: 100%;
    margin: 10px 0;
  }

  .local-job-view-1 {
    padding: 5% 2%;
  }

  #button-up {
    font-size: 0.75rem;
    padding: 8px;
    width: 100%;
  }
}
