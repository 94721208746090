:root {
    --textlight: #ffffff;
    --bgblue: #0e0534;
    --bgdark: #141414;
}

.institutes {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5% 10% 5% 10%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    
}

.leftBar{
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: top;
    margin-top: 20px;
}

.buttonsLi{
    display: grid;
    grid-template-columns: 28% 28% 28%;
    list-style-type: none;
    margin-top: 10px;
    padding: 10% 0 10% 0;
    
}

.rootLink ul {
    width: 100%;
    height: auto;
    text-align: center;
    justify-content: flex-start;
    display: flex;
    list-style: none;
    margin-top: 30px;
    padding: 20px;
    text-transform: uppercase;
    font-size: 0.8rem;
}

.rootLink ul li {
    background-color: var(--bgblue);
    margin: 0px 2px;
    border-radius: 8px 22px 22px 8px;
}

.rootLink ul li a {
    text-decoration: none;
    color: var(--textlight);
    padding: 10px 10px;
    display: block;
    font-weight: 400;
}

.institutesContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #ffffff;
    padding: 20px;
}

.containerBoxOne {
    width: 300px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #576e8886;
    padding: 10px;
    border-radius: 4px;
    
}


.containerBoxOne h4 {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 10px 0px;
    letter-spacing: 1px;
    color: #ffffff;
}

.containerBoxOne p {
    font-size: 0.6rem;
    font-weight: 400;
    margin: 10px 0px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
}
#formInstitutes {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

#formInstitutes input[type="text"],
#formInstitutes input[type="submit"] {
    width: auto;
    height: 45px;
    border-radius: 2px;
    border: none;
    outline: none;
    padding: 15px;
    letter-spacing: 1px;
}

#formInstitutes input[type="submit"] {
    background-color: #f6923a;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    color: var(--textlight);
    text-transform: uppercase;
    font-size: 0.7rem;
    border: 1px solid var(--textlight);
    border-radius: 3px;
    margin: 0px 0px 0px 8px;
    font-weight: 500;
    letter-spacing: 1px;
}

.containerBoxTwo {
    width: 100%;
    height: auto;
    padding: 0px 30px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
}

.ins-box {
    background-color: #6e7a880f;
    width: 260px;
    min-height: 200px;
    border-radius: 4px;
}

.ins-box:hover{
    transform: scale(1.1);
    transition: 400ms;
    cursor: pointer;
}
.content-warp{
    background-color: #f6923a;
    padding: 10px;
    border-radius: 4px;
}
.ins-box-img {
    height: 100px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    padding: 2px;
    height: auto;
}

.ins-box-img img {
    width: 100%;
}

.ins-box-content {
    padding: 10px;
    color: var(--bgdark);
    letter-spacing: 2px;
}

.ins-box-content p {
    color: var(--bgdark);
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 10px;
}

#new-item{
    background-color: rgb(242, 1, 1);
    color: white;
    padding: 4px ;
    border-radius: 2px;
    box-shadow: 0px 0px 5px 0px #000000;
    margin: 0px 0px 0px 4px;
}
#new-no{
    display: none;
}

@media (max-width:750px){
    .institutes{
       
        flex-direction: column;
    }
    .institutesContainer{
        width: 100%;
        flex-direction: column;
    }
    .containerBoxOne{
        width: 100%;
        margin-bottom: 20px;

    }  
    
}

@media (max-width:364px) {
    .content-warp{
        width: 100%;
    }
    #formInstitutes {
        display: grid;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    #formInstitutes input[type="text"],
    #formInstitutes input[type="submit"]{
        width: 100%;
        height: 35px;
        padding: 10px;
    }
    #formInstitutes input[type="submit"] {
        width: 100%;
        margin: 10px 0px;
        padding: 10px;
        display: flex;
        text-align: center;
        justify-content: center;
    }
    
}