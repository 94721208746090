.NavigationBar {
    width: 100%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    text-transform: uppercase;
    
}
.NavigationBar a {
    text-decoration: none;
}
/*first nav bar*/
#nav-first-section {
    width: 100%;
    height: 10vh;
    background-color: #212943;
    padding: 0px 15px 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #303c5e;
 

}

/* .logo {
    background-image: url(../images/skillslogo.png);
    background-size: contain;
    background-repeat: no-repeat; 
    background-position: center; 
    width: 250px; 
    height: 80px; 
} */

/*desktop view*/
.lan-select {
    /* height: 100%; */
    display: flex;
    align-self: center;
    justify-content: space-around;
}
.lan-select button {
    background-color: #1c253a;
    /* height: 100%; */
    padding: 10px 15px;
    margin: 0px 2px;
    outline: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.lan-select button a {
    font-size: 0.9rem;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 1.2px;
    font-family: "Noto Sans", sans-serif;
}

.lan-select button:hover {
    transition: all ease-in-out 0.08s;
}

/*menu-icon-mob-view*/
.menu-icon-mob-view {
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.menu-icon-mob-view select {
    padding: 10px 15px;
    height: 100%;
    background-color: #1c253a;
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Noto Sans", sans-serif;
    outline: none;
    border: none;
    -webkit-appearance: none; /* Remove default arrow icon for Safari */
    -moz-appearance: none; /* Remove default arrow icon for Firefox */
    appearance: none; /* Remove default arrow icon for other browsers */
}
.menu-icon-mob-view select a {
    color: #ffffff;
}

/* second-nav-bar */
.second-nav-bar {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    justify-items: flex-end;
    padding: 10px 10px;
    background-color: #0e0534;
}

.icon-box-nav {
    width: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon-box-nav a i {
    font-size: 1.2rem;
    color: #ffffff;
}

/*third-nav*/
.third-nav {
    width: 100%;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0e0534; /*#275eea*/
    border-top: 2px solid #303c5e;
}

.menu-list-nav2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-list-nav2-ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    list-style: none;
    gap: 35px;
}
.menu-list-nav2-ul li {
    padding: 10px 15px;
    border-radius: 2px;
    text-align: center;
}
.menu-list-nav2-ul li a {
    font-size: 0.9rem;
    color: #efeff3;
    font-weight: bold;
}

.menu-list-nav2-ul li:nth-child(1) {
    background: #eb7d23;
}

.menu-list-nav2-ul li:nth-child(2) {
    background: #f3b519;
}

.menu-list-nav2-ul li:nth-child(3) {
    background: #86cd13;
}
.menu-list-nav2-ul li:nth-child(4) {
    background: #1ea851;
}

.menu-list-nav2-ul li:nth-child(5) {
    background: #d3113b;
}

.menu-list-nav2-ul li:nth-child(6) {
    background: #058d86;
}

.menu-list-nav2-ul li:nth-child(7) {
    background: #a012ad;
}
.menu-list-nav2-ul li:nth-child(8) {
    background: #0d4c94;
}
.menu-list-nav2-ul li:nth-child(9) {
    background: #a81488;
}
.menu-list-nav2-ul li:nth-child(10) {
    background: #9f24b2;
}
.menu-list-nav2-ul li:nth-child(11) {
    background: #e76b06;
}

.menu-list-nav2-ul li:nth-child(12) {
    background: #faba17;
}
.menu-list-nav2-ul li:nth-child(13) {
    background: #0ac58a;
}

.menu-list-nav2-ul li:nth-child(14) {
    background: #048ea7;
}
.menu-list-nav2-ul li:nth-child(15) {
    background: #b5098a;
}

#drop2 {
    position: relative;
    display: inline-block;
}

#drop2 a {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#drop2:hover {
    background-color: #a012ad;
    border-radius: 4px;
    padding: 10px 15px;
}
.dropdown-content2 {
    height: 250%;
    position: absolute;
    top: 40px;
    left: 0%;
    width:100%;
    display: none;
    gap: 0px;
    padding: 0px;
    background-color: #a012ad;
    border-radius: 4px;
}
.dropdown-content2 a{
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: bold;
    padding: 10px 15px;
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border-top: 1px solid #ffffff;

}
.dropdown-content2 a:hover {
    background-color: #760e7e;
}
#drop2:hover .dropdown-content2 {
    display: grid;
}
#drop2 a i {
    margin: 0px 0px 0px 10px;
}


@media (max-width: 1305px) {
    .menu-list-nav2-ul {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .icon-box-nav {
        width: 20%;
    }

    .logo {
        width: 150px; 
        height: 100px;
        margin-top: -45px;
        margin-left: -30px;
    }
}

@media (max-width: 1045px) {
    .menu-list-nav2-ul {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .icon-box-nav {
        width: 40%;
    }
}

@media (max-width: 795px) {
    .menu-list-nav2-ul {
        grid-template-columns: 1fr 1fr;
    }
    .menu-list-nav2-ul {
        gap: 20px;
    }
    .icon-box-nav {
        width: 50%;
    }
    .menu-icon-mob-view {
        display: flex;
    }
    .lan-select{
        display: none;
    }
    #nav-first-section {
        padding: 0px 0px 0px 15px;
        
    }

    #drop2:hover {
        background-color: #a012ad;
        border-radius: 4px;
        padding: 10px 15px;
    }

    .logo {
        width: 150px;
        height: 100px;
        margin-top: -45px;
        margin-left: -30px;
        
    }
  
}
@media (max-width: 425px) {
    .menu-list-nav2-ul {
        grid-template-columns: 1fr 1fr;
    }
    .menu-list-nav2-ul li a {
        font-size: 0.8rem;
    }
    .icon-box-nav {
        width: 60%;
    }
    .menu-icon-mob-view {
        display: flex;
    }
    .lan-select{
        display: none;
    }

    .logo {
        width: 150px; 
        height: 100px; 
    }
}

@media (max-width: 390px) {
    .icon-box-nav {
        width: 80%;
    }
    .menu-icon-mob-view {
        display: flex;
    }
    .lan-select{
        display: none;
    }
}
