.bank-create-stdLoan{
    width: 100%;
    padding: 10px 0px;
    margin: 70px 0px 0px 0px;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    margin-top: -10px;
}



#form-create-stdLoan{
    width: 100%;
}

#form-create-stdLoan form{
    width: 50%;
}

.formgroup select{
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    
}