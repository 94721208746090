.c_container {
    display: flex;
    font-family: Arial, sans-serif;
    font-size: 16px;
}

.c_leftcolumn {
    width: 60%;
    padding: 65px;
    margin-left: 90px;
    letter-spacing: 1.2px;
}

.programme {
    padding: 10px;
}

.programme h2 {
    padding: 12px;
    text-transform: uppercase;
}

#hr-programme {
    width: 100px;
    height: 3px;
    background-color: rgb(64, 72, 130);
    outline: none;
    border: none;
    border-radius: 6px;
    margin: 5px 0px;
    margin-left: 10px;
}

.programmes {
    padding: 10px;
}

.programmes h2 {
    padding: 12px;
}

#hr-programmes {
    width: 100px;
    height: 3px;
    background-color: rgb(64, 72, 130);
    outline: none;
    border: none;
    border-radius: 6px;
    margin: 5px 0px;
    margin-left: 10px;
}



 .c-image img{
    width: 100%;
    height: auto;
    margin-top: 20px;
    background-size: cover; 
    background-position: center; 
    margin-left: 15px;
  }



.programme h3 {
    padding: 12px;
    font-size: 21px;
    margin-top: 40px;
}

.programme p {
    padding: 12px;
    line-height: 30px;
    color: rgb(69, 69, 69);
    letter-spacing: 1px;
}

#p-overview {
    padding: 12px;
    line-height: 30px;
    color: rgb(69, 69, 69);
    justify-self: left;
    text-align: justify;
}

.programme h4 {
    padding: 12px;
}

.programme ol {
    padding: 12px;
    color: rgb(69, 69, 69);
    line-height: 40px;
    margin-left: 25px;
}

.programme h3 {
    padding: 12px;
}

.programme table {
    padding: 12px;
    margin-bottom: 15px;
    width: 90%;
    border-collapse: collapse;
}

.programme table, .programme th, .programme td {
    border: 1px solid rgba(0, 0, 0, 0.305);
}

.programme th, .programme td {
    padding: 8px;
    text-align: left;
}

a {
    color: rgb(69, 69, 69);
    text-decoration: none;
}

a:hover {
    color: rgb(9, 9, 210);
    text-decoration: underline;
}

.courseitem {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    margin: 12px;
    border-radius: 5px;
    width: 350px;
    text-align: left;
    line-height: 20px;
    margin-top: 40px;
}

.courseitem hr {
    margin: 10px 0;
    border: 0;
    border-top: 1px solid #cccccc55;
}

.coursetitle a {
    text-decoration: none;
    color: #007BFF;
    font-size: 18px;
}

.coursetitle a:hover {
    color: #000000;
}

.coursedetails {
    font-size: 14px;
    color: #555;
    margin-top: 5%;
    margin-bottom: 5%;
}

.courseins {
    font-size: 12px;
    color: #7d7c7c;
    margin-top: 5%;
    font-weight: bolder;
}

.courseins a {
    font-size: 12px;
    color: #555;
}

.c_rightcolumn {
    width: 30%;
    padding: 65px;
    margin-top: 25px;
    margin-right: 150px;
    letter-spacing: 1.2px;
}

.c_rightcolumn table {
    width: 120%;
    border-collapse: collapse;
    border-radius: 2px;
    overflow: hidden;
}

.c_rightcolumn td {
    padding: 15px;
    background-color: #e0e0e0;
    color: #333;
    align-items: center;
}

.c_rightcolumn .icon-text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.c_rightcolumn .icon-text i {
    margin-right: 10px;
    color: #555;
}

.programme form {
    width: 100%; 
    max-width: 600px;
    margin: 20px auto; 
}
.download-btn{
    background-color: #20be35; 
    color: white;
    border-radius: 8px;
    padding: 12px 12px; 
    font-size: 15px; 
    cursor: pointer;
    transition: background-color 0.3s ease;
    letter-spacing: 1px;
    border-color: #dddddd;
}
  
.download-btn:hover{
    background-color: #5fdd3c; 
}

.a_rightcolumn {
    width: 40%;
    padding: 65px;
    margin-top: 25px;
    margin-right: 10%;
    letter-spacing: 1.2px;
}

.a_rightcolumn table {
    width: 120%;
    border-collapse: collapse;
    border-radius: 2px;
    overflow: hidden;
}

.a_rightcolumn td {
    padding: 15px;
    background-color: #e0e0e0;
    color: #333;
    align-items: center;
   
}

.a_rightcolumn .icon-text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.a_rightcolumn .icon-text i {
    margin-right: 10px;
    color: #555;
}
 
.examination-structure {
    margin: 20px 0;
}

.examination-structure h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.examination-structure table {
    width: 120%;
    border-collapse: collapse;
    background-color: #eaeaea;
}

.examination-structure th,
.examination-structure td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    
}

.examination-structure th {
    background-color: #bdbdbd;
}



@media screen and (max-width: 1140px) {
    .c_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .c_leftcolumn {
        width: 100%;
        padding: 30px;
        margin-left: 0;
    }

    .c_rightcolumn {
        width: 100%;
        padding: 30px;
        margin-top: -1800px;
        margin-right: 0;
        margin-bottom: 1300px;
    }

    .courseitem {
        width: 100%;
    }

    .programme table {
        width: 100%;
    }

    #p-overview {
        text-align: left;
    }

    .programmes {
        margin-bottom: 650px;
    }
}

@media screen and (max-width: 480px) {
    .c_leftcolumn {
        padding: 10px;
    }

    .programme h2 {
        font-size: 22px;

    }

    #hr-programme {
        width: 50px;
        height: 2px;
    }

    .c-image img{
        width: 44vh;
        height: 30vh;
        margin-top: 8px;
        margin-left: 2px;
      }

    .programme p {
        line-height: 24px;
        
    }

    .programme ol {
        line-height: 30px;
        margin-left: 15px;
    }

    .programme table,
    .programme th,
    .programme td {
        font-size: 14px;
    }

    .courseitem {
        padding: 15px;
        margin: 10px;
    }

    .coursetitle a {
        font-size: 16px;
    }

    .coursedetails {
        font-size: 12px;
    }

    .courseins {
        font-size: 10px;
    }

    .c_rightcolumn {
        padding: 10px;
    }

    .c_rightcolumn table {
        width: 100%;
    }

    .c_rightcolumn td {
        padding: 10px;
    }

    .c_rightcolumn .icon-text i {
        margin-right: 5px;
    }

    .programme form {
        width: 100%;
        padding: 10px;
    }
}
