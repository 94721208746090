.institute-create-course{
    width: 100%;
    padding: 10px 0px;
    margin: 70px 0px 0px 0px;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}

#form-create-institute{
    width: 100%;
}

#form-create-institute form{
    width: 50%;
}

.formgroup select{
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    
}