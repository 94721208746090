#GCreate{
    width: 100%;
    height: auto;
    margin-top: 100px;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

#gcd{
    width: 100%;
    height: 100vh;

}

.nav-gc{
    width: 100%;
    height: auto;
    background-color: #0e0534;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
  
    z-index: 1000;
    padding: 25px;
    color: aliceblue;
}

.g-text{
    text-transform: uppercase;
}
#nav-mobile-gc{
    display: flex;
    list-style: none;
}

#nav-mobile-gc li button{
    background-color: #f91313;
    padding: 10px;
    color: aliceblue;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 6px;
    
}

