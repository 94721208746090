.institute-container{
    width: 100%;
    padding: 10px 50px;
    margin: 100px 0px 0px 0px;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}

.institute-admin-box1{
    width: 100%;
    display: flex;
    align-self: center;
    
    justify-content: space-around;
}

.institute-box-profile{
    display: grid;
    justify-content: center;
}

.admin-profile-edit{
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-end;
    justify-content: flex-end;
    padding: 10px 0px;
}

.admin-profile-edit button{
    background-color: #2dd872;
    padding: 10px;
    text-transform: uppercase;
    outline: none;
    border: none;
    border-radius: 4px;
}

.admin-profile-edit button a{
    text-decoration: none;
    color: rgb(18, 18, 18);
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    
}

.admin-profile-edit button a i{
    margin-left: 8px;
    font-size: 1.2rem;
}