.apply-form {
  width: 170%;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}

.apply-form h3 {
  text-align: left;
  margin-bottom: 20px;
  font-size: 18px; 
  color: #333;
}

#hr-programme {
  width: 100px;
  height: 3px;
  background-color: rgb(64, 72, 130);
  outline: none;
  border: none;
  border-radius: 6px;
  margin: 5px 0;
  margin-left: 10px;
}

.mainform {
  margin-left: -150px;
  width: 150%;
}

.apply-form .form-row {
  display: flex;
  gap: 15px; 
  margin-bottom: 15px;
}

.apply-form .form-group {
  flex: 1; 
  margin-bottom: 15px;
}

.apply-form label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.apply-form input,
.apply-form textarea,
.apply-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}

.apply-form textarea {
  height: 100px;
  resize: vertical;
}

.apply-form .captcha {
  display: flex;
  align-items: center;
}

.apply-form .captcha span {
  margin-left: 10px;
  font-size: 14px;
}

.apply-form button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.apply-form button:hover {
  background-color: #0056b3;
}

.half-width {
  flex: 1;
  margin-right: 15px;
}

.half-width:last-child {
  margin-right: 0; 
}

.apply-form .add-half{
 flex: 1;
  margin-right: 15px;
}


@media screen and (max-width: 1140px) {
  .apply-form {
      width: 100%;
      margin: 30px auto;
      padding: 15px;
  }

  .mainform {
      margin-left: 0;
      width: 100%;
  }

  .apply-form .form-row {
      flex-direction: column;
      gap: 10px;
  }

  .apply-form .form-group {
      margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  .apply-form {
      width: 100%;
      padding: 10px;
      margin: 10px 0;
  }

  .apply-form h3 {
      font-size: 16px;
  }

  .apply-form input,
  .apply-form textarea,
  .apply-form select {
      font-size: 14px;
      padding: 8px;
  }

  .apply-form button {
      padding: 8px 15px;
      font-size: 14px;
  }

  .mainform {
      margin-left: 0;
      width: 100%;
  }

  .apply-form .form-row {
      flex-direction: column;
      gap: 10px;
  }

  .apply-form .form-group {
      margin-bottom: 10px;
  }
}

