.instituteregister {
    width: 100%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.head-content-text {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    color: #181616;
    background-color: #e0e0e6;
    text-align: center;
    padding: 20px 10px;
    text-transform: uppercase;
}


.form-content {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
    box-shadow:
        rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 50px 0px;
    border-radius: 2px;
    background-color: #e0e0e6;
}

.form-content form {
    width: 80%;
}

.form-group-1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #f6923a;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 20px 0px;
}

.form-group-1 input,
select {
    width: 100%;
    padding: 12px;
    margin: 10px 0px;
    border: 1px solid #e0e0e6;
    border-radius: 4px;
    border: none;
    outline: none;
}

.form-group-1 i {
    font-size: 1.1rem;
    color: #1b1818;
    margin: 0px 10px 0px 0px;
}

::placeholder {
    font-family: "Noto Sans", sans-serif;
    letter-spacing: 1px;
}
.textarea {
    padding: 10px;
}

.textarea textarea {
    width: 100%;
    height: 200px;
    outline: none;
    border: none;
}

.form-group-1 input[type="submit"] {
    font-family: "Noto Sans", sans-serif;
    background-color: #242321;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}



#span-form{
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
}




.form-paragraph {
    font-size: 0.9375rem;
    color: #e8edea;
    font-family: "Noto Sans", sans-serif;
}


.custom-font {
    font-family: "Noto Sans", sans-serif;
    color: #333; 
}


.drop-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    transition:
        background 0.2s ease-in-out,
        border 0.2s ease-in-out;
    font-family: "Noto Sans", sans-serif;
}

.drop-container:hover .drop-title {
    color: #222;
}

.drop-title {
    color: #f3e8e8;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color 0.2s ease-in-out;
    font-family: "Noto Sans", sans-serif;
}

#file-input {
    width: 350px;
    max-width: 100%;
    color: #444;
    padding: 2px;
    background: #fff;
    border-radius: 2px;
    border: 1px solid rgba(8, 8, 8, 0.288);
    font-family: "Noto Sans", sans-serif;
}

#file-input::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #f6923a;
    padding: 10px 20px;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    font-family: "Noto Sans", sans-serif;
}

#file-input::file-selector-button:hover {
    color: #fff;
    font-family: "Noto Sans", sans-serif;
}

@media (max-width: 990px) {
    .form-content {
        width: 100%;
    }
    .form-content form {
        width: 100%;
    }
    #mobile_code {
        width: 100%;
    }
}
