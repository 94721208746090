.EventSecond {
    width: 100%;
    height: auto;
    font-family: "Noto Sans", sans-serif;
}

.EventSecond a {
    text-decoration: none; 
    color:#000000;
}

.EventSecond a:hover {
    color: inherit; 
    text-decoration: none; 
}

.EventSecond a h4 {
    font-size: 18px;
}

.EventSecond a h4:hover {
    color:#464646; 
    text-decoration: none;
}

.boxevent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5% 10% 5% 10%;
    font-family: "Noto Sans", sans-serif;
}

.containerBoxOne-event {
    width: 300px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #576e8886;
    padding: 10px;
    border-radius: 4px;
}

.containerBoxOne-event h4 {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 10px 0px;
    letter-spacing: 1px;
    color: #ffffff;
    transition: color 0.3s ease; 
}

.containerBoxOne-event p {
    font-size: 0.6rem;
    font-weight: 400;
    margin: 10px 0px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
}

.evnt-box-content {
    padding: 8px;
    font-family: "Noto Sans", sans-serif;
}

.evnt-box-content:hover {
    text-decoration: none;
}

.evnt-box-content p,
.fotter-box-event p {
    font-weight: 400;
    font-size: 14px;
    margin: 4px 0px 0px 0px;
}

.fotter-box-event {
    padding: 10px;
    border-top: 1px solid #3b373440;
    font-family: "Noto Sans", sans-serif;
}

.containerBoxtwoevent {
    width: 100%;
    display: grid;
    align-self: center;
    justify-content: center;
    flex-direction: column;
}

.containerBoxevent {
    width: 100%;
    height: auto;
    padding: 0px 30px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
}

.ins-box-event {
    background-color: #6e7a880f;
    width: 260px;
    min-height: 200px;
    border-radius: 4px;
    overflow: hidden; 
    position: relative;
}

.ins-box-img {
    width: 100%;
    height: 100px; 
    overflow: hidden;
    position: relative;
}


@media (max-width: 960px) {
    .boxevent {
        flex-direction: column;
        padding: 15px 15px;
    }

    .containerBoxOne-event {
        width: 100%;
    }

    .containerBoxevent {
        padding: 20px 15px;
    }
}

@media (max-width: 600px) {
    .containerBoxOne-event {
        padding: 5px;
    }

    .evnt-box-content p,
    .fotter-box-event p {
        font-size: 0.8rem;
    }

    .select-bar-event {
        padding: 10px 20px;
    }

    .select-box {
        padding: 10px 5px;
    }
}
