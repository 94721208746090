.AllViews-admin {
    width: 100%;
    margin: 13vh 0px 0px 0px;
    padding: 20px;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}

.table-institute {
    width: 100%;
}

.table-institute table {
    width: 100%;
    border-radius: 4px;
   
}

.table-institute table thead tr th {
    padding: 12px;
    background-color: #242427;
    color: #ffffff;
    font-weight: 500;
}

.scroller-box {
    width: 100%;
    height: 80vh;
    overflow-x: auto;
    overflow-y: auto;
    padding: 5px 0px;
}
.scroller-box {
    scrollbar-width: thin;
    scrollbar-color: #111111d7 #e9e9f0;
  }
.scroller-box::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
    border: 3px solid #242427;
}

.scroller-box::-webkit-scrollbar-track {
    background: #6b6b71;
    border-radius: 5px;
}

.scroller-box::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 14px;
    border: 3px solid var(--primary);
}

.table-institute table tbody tr:nth-child(even) {
    background-color: #d0d2d6;
}
.table-institute table tbody tr:hover {
    background-color: #c9c9d4;
}

.table-institute table tbody tr td {
    padding: 12px;
    color: #000000;
}

.admin-institute-search {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    background-color: #d0d3db;
}

#view-web,
#del-details,
#app-details {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4px;
    font-size: 0.9rem;
    border-radius: 4px;
    text-decoration: none;
    color: #ffffff;
}

#view-web {
    background-color: #3a6cf6;

}

#view-web a{
    color: #ffffff;
    text-decoration: none;
}
#del-details {
    background-color: #f44336;
}
#app-details {
    background-color: #33c66e;
}
#action-buttons {
    display: flex;
    gap: 5px;
}

@media(max-width: 768px) {
    
    .admin-institute-search{
        display: grid;
        gap: 10px;
    }
    .table-institute table{
        width: 140%;
    }
}

@media(max-width: 618px){
    .table-institute table{
        width: 160%;
    }
}

@media(max-width: 522px){
    .table-institute table{
        width: 160%;
    }
}