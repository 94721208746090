@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

:root {
    --textlight: #ffffff;
    --bgblue: #0e0534;
    --bgdark: #141414;
}

* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

/* .logo {
    height: 100px;
    width: 50px;
} */
/* body {
    width: 100%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
   
} */

.Navigation {
    width: 100%;
    min-height: 15vh;
    background-color: var(--bgblue);
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}

.drop {
    position: relative;
    display: inline-block;
}
.drop a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.drop a i {
    margin: 0px 0px 0px 10px;
}

.drop a:hover {
    background-color: #f6923a;
    border-radius: 4px;
}

.dropdown-content {
    height: auto;
    position: absolute;
    width: 220px;
    display: none;
    gap: 10px;
    padding: 0px;
    background-color: #f6923a;
    border-radius: 4px;
}

.dropdown-content a {
    padding: 10px 5px;
}

.dropdown-content a:hover {
    background-color: #f6923a;
}

.drop:hover .dropdown-content {
    display: grid;
}

.drop2 {
    position: relative;
    display: inline-block;
}

.drop2 a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.drop2 a i {
    margin: 0px 0px 0px 10px;
}
.drop2 a:hover {
    background-color: #f6923a;
    border-radius: 4px;
}
.dropdown-content2 {
    height: auto;
    position: absolute;
    width: 100%;
    display: none;
    gap: 4px;
    padding: 0px;
    background-color: #a012ad;
    border-radius: 4px;
}

.drop2:hover .dropdown-content2 {
    display: grid;
}

#nav {
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.menul-ist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
}
.menul-ist li {
    padding: 5px;
    margin: 0px 10px;
}
.menul-ist li a {
    text-decoration: none;
    color: var(--textlight);
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 10px;
}

.menul-ist li a:hover{
    background-color: #f6923a;
    border-radius: 4px;

}
.menuActive{
    background-color: #f6923a;
    border-radius: 4px;
}
.reg-btn-menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    color: var(--textlight);
    text-transform: uppercase;
    font-size: 0.8rem;
    border: 1px solid var(--textlight);
    padding: 8px;
    border-radius: 3px;
    margin: 0px 0px 0px 8px;
}

.reg-btn-menu:hover {
    color: #f6923a;
}
.reg-btn-menu i{
    margin: 0px 0px 0px 5px;
}

.menu-icon {
    margin: 0px 0px 0px 12px;
}


.header-top {
    background-color: #c4c1d1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
    width: 100%;
    height: auto;
}
.header-top p {
    font-size: 0.8rem;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.header-top select {
    background-color: #f6923a;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 2px;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 15px;
    margin: 0px 0px 0px 8px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
}
.header-top select a{
    font-size: 0.8rem;
    font-weight: 500;
}
.header-top select option {
    background-color: #f6923a;
    color: #fff;
  
   
    
}

#menu-button {
    display: none;
    /* menu button display none in 1200 px*/
}

/*Nav Bar Css End*/

/* Footer Css Start */

footer {
    background-color: #000000;
    color: #fff;
    padding: 50px 0;
    text-align: center;
    font-family: "Noto Sans", sans-serif;
    text-transform: uppercase;
    font-size: 0.9rem;

}

.footer-logo {
    margin-bottom: 110px;
    background-image: url(../images/skillslogo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 250px;
    height: 80px;
    display: flex;
    justify-content: center; 
    align-items: center;
    margin: 10px auto; 
}

.footer-nav {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-top: 50px;
}

.footer-nav a {
    color: #bebdc2;
    text-decoration: none;
    margin: 10px;
}

.footer-nav a:hover {
    text-decoration: none;
    color: #fff;
}

.social-links {
    margin-bottom: 25px;
   
}

.social-links a {
    margin: 10px;
    font-size: 25px;
    color: #bebdc2;
    transition: color 0.3s ease;
    padding: 8px;
}

.social-links a:hover {
    text-decoration: none;
    color: #fff;
   
}

.copyright{
    margin-top: 50px;
    font-size: 15px;
    color: #bebdc2;
}


/* Footer Css End */

/* Start Media Query Nav Bar */

@media (max-width: 1140px) {
    #nav {
        display: flex;
        justify-content: space-between;
        padding: 0px 50px;
    }

    #menu-button {
        display: flex;
    }

    .menul-ist {
        display: none;
        position: absolute;
        top: 22vh;
        right: 0px;
        width: 100%;
        height: auto;
        background-color: var(--bgblue);
        padding: 10px;
        transform: translateX(100%);
        transition: 0.1ms all ease;
    }

    .nav_active {
        transform: translateX(0); /* Nav bar active css*/
        display: grid;
    }

    .menul-ist li {
        margin: 8px 0px;
    }

    .reg-btn {
        margin: 8px 0px;
    }

    .footer-nav {
        font-size: 13px;
        display:flex;
        justify-content: center;
        
    }

    .social-links a {
        margin: 10px;
        font-size: 20px;
        color: #bebdc2;
        transition: color 0.3s ease;
        padding: 1px;
    }
    
    .copyright{
        margin-top: 50px;
        font-size: 10px;
        color: #bebdc2;
        
    }
    
}

@media (max-width: 768px) {
    #nav {
        padding: 0px 20px;
    }
    .menul-ist {
        top: 20vh;
    }
}
/* End Media Query Nav Bar */
