.institute-create-pe{
    width: 100%;
    padding: 10px 0px;
    margin: 70px 0px 0px 0px;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}

#form-create-institute{
    width: 100%;
}

#form-create-institute form{
    width: 50%;
}

.formgroup select{
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    
}

.pe_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}

.pe-new{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}


.pe_container form {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 50px 0px;
    border-radius: 2px;
    background-color: #e0e0e6;
}


.pe_container form textarea {
    width: 100%;
    padding: 40px;
    border: 1px solid #e0e0e6;
    border-radius: 4px;
    outline: none;
}

.pe_container form button {
    font-family: "Noto Sans", sans-serif;
    background-color: #242321;
    color: #ffffff;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 30%;
    margin: 20px auto 0 auto;
    font-size: 18px;
}

.pe_container form button:hover {
    background-color: #2e2e2e;
}

.pe_container form .half {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
}

.pe-formgroup {
   width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f6923a;
    padding: 10px;
    border-radius: 4px;
    margin: 12px 0px;
  }
  
  .pe-formgroup img {
    margin-top: 10px;
    border: 1px solid #ddd;
    width:30% ;  
}
  
  .es-title{
    margin-left: 43%;
    margin-bottom: 10px;
  }

  .pe-formgroup-table {
    width: 80%;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f6923a;
    padding: 10px;
    border-radius: 4px;
    margin: 12px 0px;
  }

.pe-add-half{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
    margin-left: 20px;
}

.pe-formgroup input, .pe-formgroup select {
    width: 100%;
    padding: 12px;
    border: none;
    outline: none;
    border-radius: 4px;
}

.pe-formgroup i {
    font-size: 1.1rem;
    color: #1b1818;
    margin-right: 10px;
}


.pe-formgroup-table table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.pe-formgroup-table table th, .pe-formgroup-table table td {
    border: 1px solid #e0e0e6;
    padding: 10px;
    text-align: left;
}

.pe-formgroup-table table th {
    background-color: #f6923a;
    color: rgb(0, 0, 0);
    font-size: 14px;
}

.pe-formgroup-table table td input {
    width: 100%;
    padding: 8px;
    border: 1px solid #e0e0e6;
    border-radius: 4px;
}

.pe-formgroup-table table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.pe-formgroup-table table tr:nth-child(odd) {
    background-color: #ffffff;
}


.pe-formgroup-table button {
    background-color: #242321;
    color: #ffffff;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 4px;
}

.pe-formgroup-table button:hover {
    background-color: #2e2e2e;
}


.pe-formgroup-table .add-row-btn {
    background-color: #46c94a; 
    color: #ffffff;
    width: 100px;
    font-size: 15px;
    padding: 4px;
    margin-left: 34rem;
    margin-top: -10px;
}

.pe-formgroup-table .add-row-btn:hover {
    background-color: #39b43f;
}


.pe-formgroup-table .remove-row-btn {
    background-color: #f44336;
    color: #ffffff;
    width: 90px;
    font-size: 15px;
    padding: 4px;
    margin-top: -10px;
}

.pe-formgroup-table .remove-row-btn:hover {
    background-color: #e53935;
}

@media(max-width:990px){
    .pe_container  {
        width: 100%;
        padding: 20px;
    }

    .pe_container form {
        width: 100%;
        padding: 15px;
    }

    .pe_container form button {
        width: 50%;
    }

    .pe-formgroup-table {
        overflow-x: auto; 
        -webkit-overflow-scrolling: touch; 
    }

    .pe-formgroup-table table {
        width: 560%;
    }

    .pe-formgroup-table th, .pe-formgroup-table td {
        white-space: nowrap; 
    }

    .pe-formgroup-table .add-row-btn {
        margin-left: 71rem;
    
    }

    .pe-formgroup-table h4{
        margin-left: 0px;
    }
}
