.AllViews-admin2-dc {
    margin-top: 100px;

}

.date-set-dc {
    margin-top: 50px;
    margin: 0px 10px;
    padding: 20px;
    display: flex;
    align-items: end;
    justify-content: end;
}

.date-set-dc h2 {
    background-color: #0e0534;
    padding: 10px;
    color: white;
    border-radius: 4px;
}

.parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin: 20px 20px;
}

.div1,
.div2,
.div3,
.div4,
.div5,
.div6,
.div7,
.div8 {
    height: auto;
    padding: 20px;
    border: 1px solid #ddd;
    color: white;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.466);
    cursor: pointer;
}

.div1 {
    background-color: #17d1f6;
    color: black;
}

.div2 {
    background-color: #17f65a;
}

.div3 {
    background-color: #fb1b1b;
}

.div4 {
    background-color: #3817f6;
}

.div5 {
    background-color: #ef9922;
}

.div6 {
    background-color: #6d22ef;
}

.div7 {
    background-color: #ef226a;
}

.div8 {
    background-color: #efcd22;
}

#icon-dc {
    font-size: 30px;
}

.icon-sect-dc {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    background-color: #101418;
    color: #fff;
    padding: 10px;

}
.box-dc-1{
    margin-top: 20px;
  
}

.icon-sect-dc h2 {
    text-transform: uppercase;
}

.icon-sect-dc-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

}
.icon-sect-dc-1 h3 span{
    background-color: #1b1111;
    padding: 8px;
    color: #ffffff;
    font-size: 1.2rem;
    border-radius: 4px;
}
.icon-sect-dc-1 h2,
.icon-sect-dc-1 h3 {
    text-transform: uppercase;
}

.icon-sect-dc-12 button {
    background-color: #101418;
    color: #fff;
    padding: 10px 20px;
    margin: 20px 0px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.icon-sect-dc-12 button:hover {
    background-color: #07304c;
}

.icon-sect-dc-12 button a{
    color: #ffffff;
}



@media (max-width: 768px) {
    .parent {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media (max-width: 480px) {
    .parent {
        grid-template-columns: 1fr;
    }
}