.Contactus {
    width: 100%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-content-contact {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow:
        rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 50px 0px;
    border-radius: 2px;
    padding: 20px 10px;
    background-color: #e0e0e6;
}

.form-content-contact form {
    width: 100%;
}

.head-content-text {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    color: #181616;
    background-color: #e0e0e6;
    text-align: center;
    padding: 20px 10px;
    text-transform: uppercase;
}

.form-block-warp {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-group-1-contact {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f6923a;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 10px 20px;
}

.form-group-1-contact input {
    width: 100%;
    padding: 12px;
    margin: 10px 0px;
    border: 1px solid #e0e0e6;
    border-radius: 4px;
    border: none;
    outline: none;
}

.form-group-1-contact i {
    font-size: 1.1rem;
    color: #1b1818;
    margin: 0px 10px 0px 0px;
}

.form-group-1-contact input[type="submit"] {
    font-family: "Noto Sans", sans-serif;
    background-color: #242321;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.textarea textarea{
    padding: 10px;
    border-radius: 2px;
   
}


.custom-popup-class {
    background-color: #1f1c1c !important;
    color: #e0e0e6;
    width: 30%;
    font-family: "Noto Sans", sans-serif;
}

@media (max-width: 800px) {
    .form-content-contact {
        width: 100%;
    }
    .custom-popup-class {
        width: 100%;
    }
}

@media (max-width: 650px) {
    .form-content-contact {
        width: 100%;
    }
    .form-block-warp {
        flex-direction: column;
    }
    .custom-popup-class {
        width: 100%;
       
    }
}
