*{
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}
.content-edit-profile{
    width: 100%;
    padding: 10px;
    margin: 70px 0px 0px 0px;
}

