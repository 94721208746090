.r_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}

.new{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
}


.r_container form {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 50px 0px;
    border-radius: 2px;
    background-color: #e0e0e6;
}


.r_container form textarea {
    width: 100%;
    padding: 40px;
    border: 1px solid #e0e0e6;
    border-radius: 4px;
    outline: none;
}

.r_container form button {
    font-family: "Noto Sans", sans-serif;
    background-color: #242321;
    color: #ffffff;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 30%;
    margin: 20px auto 0 auto;
    font-size: 18px;
}

.r_container form button:hover {
    background-color: #2e2e2e;
}

.r_container form .half {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
}

.formgroup {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f6923a;
    padding: 10px;
    border-radius: 4px;
    margin: 12px 0px;
}

.add-half{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
    margin-left: 20px;
}

.formgroup input, .formgroup select {
    width: 100%;
    padding: 12px;
    border: none;
    outline: none;
    border-radius: 4px;
}

.formgroup i {
    font-size: 1.1rem;
    color: #1b1818;
    margin-right: 10px;
}

@media(max-width:990px){
    .r_container  {
        width: 100%;
        padding: 20px;
        
    }
    .r_container  form {
        width: 140%;
        padding: 15px;
    }

    .r_container form button {
        width: 50%;
    }

    .formgroup input {
        margin-left:-7px ;
    }
}