.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.signup-box {
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 450px;
}

.signup-section img {
    width: 100px;
    margin-bottom: 20px;
}

.signup-box h2 {
    margin-bottom: 25px;
    font-size: 30px;
}

.signup-input-group {
    margin-bottom: 15px;
    text-align: left;
}

.signup-input-group label {
    display: block;
    margin-bottom: 5px;
}

.signup-input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.signup-checkbox-group {
    display: flex;
    align-items: center;
    color: #727272;
}

.signup-checkbox-group input {
    margin-right: 10px; 
}

.signup-checkbox-group label {
    font-size: 12px;
}

.signup-text {
    margin-top: 20px;
    font-size: 14px;
}

.signup-text a {
    color: #0038f5;
    text-decoration: none;
}

.signup-text a:hover {
    text-decoration: underline;
}

.signup-password-container {
    position: relative;
}

.signup-password-container input {
    width: 100%; 
    padding: 10px;
    padding-right: 40px; 
    border: 1px solid #ccc;
    border-radius: 4px;
}

.signup-toggle-visibility {
    position: absolute;
    right: 10px;
    top: 74%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #aaa;
    font-size: 18px;
}

.signup-button{
    width: 40%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #0038f5;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.signup-button:hover {
    background-color: #002bb8;
}