.institutesView {
    width: 100%;
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    display: grid;
}

.homelink .nav-link {
    width: auto;
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    padding: 1% 10% 0% 10%;
}

.nav-link img {
    width: 110px;
}

#nav-link-img {
    margin: 0px -18px;
}

.inq{
    margin-top: 50px;
    margin-bottom: 100px;
}


.inview {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5% 5% 5% 10%;
}

.block-one-inview {
    width: 100%;
   
}
#hr-inview {
    width: 100px;
    height: 3px;
    background-color: rgb(64, 72, 130);
    outline: none;
    border: none;
    border-radius: 6px;
    margin: 5px 0px;
}

.heading-text-inview h3 {
    font-size: 1.2rem;
    font-weight: 700;
}

.img-inview img {
    object-fit: contain;
    border: 2px solid rgb(64, 72, 130);
    margin: 20px 0px;
    border-radius: 4px;
}

.content-inview {
    width: 100%;
    display: grid;
    gap: 20px;
}

.content-inview p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    padding: 0px 30px 0px 0px;
    text-align: justify;
    text-transform: capitalize;
    color: rgba(15, 15, 14, 0.685);
}

.map-section {
    width: 100%;
    height: 350px;
    padding: 0px 30px 0px 0px;
    margin-top: 30px;
}

.map-section iframe {
    width: 100%;
    height: 100%;
}


.block-two-inview {
    width: 560px;
    height: 300px;
    padding: 10px;
    background-color: #cccccc44;
    font-size: 0.9rem;
    border-radius: 6px;
}

.block-two-inview ul {
    list-style: none;
    display: grid;
    gap: 15px;
}
.block-two-inview ul li {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0px;
}
.block-two-inview ul li strong {
    display: flex;
    justify-content: center;
    color: rgba(36, 39, 42, 0.443);
    margin: 0px 10px 0px 0px;
}

.block-two-inview ul li strong i {
    margin: 0px 10px 0px 5px;
}


.course-list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5% 5% 5% 10%;
}
.course-block {
    text-align: right;
    display: grid;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 0px;
}

.course-list-block {
    width: 100%;
   
}
.course-list-block ul {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 20px;
    list-style: none;
    flex-wrap: wrap;
   
}
.course-list-block ul li{
    min-height: 200px;
   
    border: 2px solid #cccccc44;
}
.course-box {
    width: 250px;
    height: auto;
    display: grid;
    padding: 20px;
    
    border-radius: 6px;
}
.course-box h3 {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: capitalize;
}
.course-box p {
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 10px;
    color: rgba(26, 25, 23, 0.685);
}

.view-all-button {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.view-all-button a {
    background-color: #0e0534;
    color: aliceblue;
    padding: 10px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 0.9rem;
}

.view-all-button button {
    background-color: #0e0534;
    color: aliceblue;
    padding: 10px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 0.9rem;
}


@media (max-width: 1200px) {
    .course-list {
        width: 100%;
        padding: 30px;
    }
    .map-section {
        margin: 40px 0px 0px 0px;
    }
    .homelink .nav-link {
        width: 100%;
        padding: 20px;
    }
}

@media (max-width: 1112px) {
    .inview {
        display: grid;
        padding: 5% 5% 5% 5%;
    }
    .block-one-inview {
        width: 100%;
    }
    .block-two-inview {
        width: 100%;
    }
    .view-all-button {
        flex-direction: column;
    }
    .img-inview {
        width: 100%;
    }

    .map-section {
        margin: 20px 0px;
    }
    .course-box {
        width: 100%;
    }
    .course-block {
        width: 100%;
    }
    .course-list-block ul li {
        width: 100%;
        padding: 0%;
    }
    .nav-link {
        width: 100%;
        padding: 0%;
        display: flex;
    }
}
