.about-us {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.intro {
  text-align: center;
  margin-top: 20px;
}

.intro h2 {
  font-size: 1.5em;
  color: #333;
  margin-top: 20px;
}

.intro h1 {
  font-size: 2.5em;
  margin: 10px 0;
  color: #000;
  margin-top: 20px;
}

.vision-mission {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
}

.vision,
.mission {
  width: 45%;
  text-align: justify;
  margin-top: 20px;
  padding: 20px;
  background-color: #e0e0e6;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.vision:hover,
.mission:hover {
  background-color: #ffba7e;
}

.vision h3,
.mission h3 {
  font-size: 1.5em;
  color: #444;
  margin-top: 20px;
  text-align: center;
}

.vision p,
.mission p {
  font-size: 1em;
  color: #666;
  margin-top: 20px;
  text-align: center;
}

.discover {
  margin-top: 40px;
  margin-bottom: 100px;
}

.discover h3 {
  font-size: 1.2em;
  color: #333;
  text-align: center;
  margin-top: 20px;
}

.discover-content {
  margin-top: 20px;
  position: relative;
}

.discover-image {
  width: 100%;
  height: auto;
  background-image: url(../images/img1.jpg);
  background-size: cover;
  background-position: center;
  height: 500px; 
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 15px;
}

.discover-text {
  padding: 20px;
}

.discover-text h2 {
  font-size: 2em;
  margin: 10px 0;
  color: #000;
  margin-top: -10px;
}

.discover-text p {
  font-size: 1em;
  color: #555;
  line-height: 1.6;
  margin-top: 20px;
}

.discover-text ul {
  margin-top: 20px;
  padding-left: 20px;
}

.discover-text ul li {
  font-size: 1em;
  color: #555;
  margin-top: 10px;
  line-height: 1.6;
}


@media screen and (max-width: 1140px) {
  .discover-content {
    flex-direction: column;
    align-items: center;
  }

  .discover-image {
    height: 380px;
    margin-bottom: 10px;
    border-radius: 10px;
  }

  .discover-text {
    padding-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .intro h1 {
    font-size: 2em;
  }

  .intro h2 {
    font-size: 1em;
  }

  .vision,
  .mission {
    width: 100%;
    padding: 15px;
  }

  .vision h3,
  .mission h3 {
    font-size: 1.2em;
  }

  .vision p,
  .mission p {
    font-size: 0.9em;
  }

  .discover h3 {
    font-size: 1em;
  }

  .discover-text h2 {
    font-size: 1.5em;
  }

  .discover-text p {
    font-size: 0.9em;
  }
  .vision-mission {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
  }
}

@media screen and (max-width: 480px) {
  .intro h1 {
    font-size: 1.5em;
  }

  .intro h2 {
    font-size: 0.8em;
  }

  .vision,
  .mission {
    width: 100%;
    padding: 10px;
  }

  .vision h3,
  .mission h3 {
    font-size: 1em;
  }

  .vision p,
  .mission p {
    font-size: 0.8em;
  }

  .discover h3 {
    font-size: 0.8em;
  }

  .discover-text h2 {
    font-size: 1.2em;
  }

  .discover-text p {
    font-size: 0.8em;
  }

  .vision-mission {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
  }
}
