.sContainer {
    display: flex;
    font-family: Arial, sans-serif;
    letter-spacing: 1.2px;
}

.left-column {
    width: 35%;
    padding: 65px;
    margin-left: 60px;
}

.search-section h2 {
    margin-bottom: 10px;
    margin-left: 65px;
    font-size: 18px;
}

.Search-container {
    text-align: center;
    margin: 20px 0;
    width: 120%;
}

.Search-container input[type="text"] {
    width: 60%;
    padding: 10px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.Search-container button {
    padding: 10px 20px;
    border: none;
    background-color: #ffad31;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    font-weight: lighter;
}

.countriesSection h3{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    margin-left: 65px;
    text-transform: capitalize; 
}

.Levels-section h3 {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    text-transform: capitalize; 
}

.countriesSection hr {
    border: 0;
    border-top: 1px solid #cccccc52;
    margin: 8px 0;
    margin-left: 60px;
    width: 320px;
}

.Uni-section hr {
    border: 0;
    border-top: 1px solid #cccccc52;
    margin: 8px 0;
    margin-left: 60px;
    width: 320px;
}

.expand-arrow {
    font-size: 12px;
    color: #ffffff;
    margin-right: 5px;
    transition: transform 0.3s ease;
}

.countriesSection h3 span.expand-arrow {
    transform: rotate(0deg);
}

.Levels-section h3 span.expand-arrow {
    transform: rotate(0deg); 
}

.countriesSection h3:hover .expand-arrow,
.Levels-section h3:hover .expand-arrow {
    color: #ffffff;  
}

/* .countriesSection ul,
.Levels-section ul {
    padding-left: 20px;  
    max-height: 300px; 
    overflow-y: auto;
} */

.categories-section ul,
.Uni-section ul,
.Levels-section ul {
    padding-left: 20px;
    max-height: none; 
    overflow-y: visible; 
}

/* Filter List Styling */
.filter-list {
    list-style: none;
    padding: 0;
    font-size: 14px;
    margin-left: 80px;
}

.filter-list li {
    margin: 10px 0;
}

.filter-list input[type="button"] {
    display: block;
    width: 185px;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin: 5px 0;
    margin-left: 0px;
}

/* Filter List Styling */
.filter-list1 {
    list-style: none;
    padding: 0;
    font-size: 14px;
    margin-left: 100px;
}

.filter-list1 li {
    margin: 10px 0;
}

.filter-list1 input[type="button"] {
    display: block;
    width: 185px;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin: 5px 0;
    margin-left: 0px;
}

.filterlist {
    list-style: none;
    padding: 0;
    font-size: 14px;
    margin-left: 80px;
}

.filterlist li {
    margin: 2px 0;
}


.filterlist input[type="button"] {
    width: 185px; 
    padding: 10px; 
    margin: 5px 0;
    cursor: pointer; 
    border: none; 
    border-radius: 5px;
    color: white; 
}


.buttonUSA { background-color: #1411b9; color: #ffffff; }
.buttonCanada { background-color: #e01515; color: #ffffff; }
.buttonUK { background-color: #32c445; color: #ffffff; }
.buttonAustralia { background-color: #d121c6; color: #ffffff; }
.buttonGermany { background-color: #232323; color: #ffffff; }
.buttonFrance { background-color: #1cc7c4; color: #ffffff; }
.buttonChina { background-color: #5841bd; color: #ffffff; }
.buttonJapan { background-color: #e66439; color: #ffffff; }
.buttonIndia { background-color: #c0a451; color: #ffffff; }

.button-foundation { background-color: #92adc4; }
.button-training { background-color: #6c757d; }
.button-certificate { background-color: #dc3545; }
.button-diploma { background-color: #1766b6; }
.button-advanced-diploma { background-color: #6610f2; }
.button-hnd { background-color: #e83e8c; }
.button-degree { background-color: #fd7e14; }
.button-bachelor { background-color: #20c997; }
.button-postgraduate-diploma { background-color: #007bff; }
.button-masters { background-color: #17a2b8; }
.button-phd { background-color: #6f42c1; }


.right-column {
    width: 60%;
    padding: 50px;
    margin-top: 50px;
    margin-right: 200px;
}

.Course {
    display: flex;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #cccccc7e;
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    text-align: left;
}

.Date {
    width: 50px;
    text-align: center;
    background-color: #9ac7f6;
    padding: 10px 0;
    margin-right: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #0c59aa;
    height: 45%;
}

.Date-top {
    height: 10%;
    background-color: #0c59aa;
    width: 50px;
}

.Course-info a {
    text-decoration: none;
    cursor: pointer;
    color: #007BFF;
    font-size: 18px;
    margin: 0 0 10px;
}

.Course-info a:hover {
    color: #000000;
}

.Course-info p {
    font-size: 14px;
    color: #555;
    margin: 5px 0;
}

.Course-info p1 {
    font-size: 12px;
    color: #7d7c7c;
    margin-top: 8%;
    font-weight: bolder;
}

.Course-info p1 a {
    font-size: 12px;
    color: #555;
}

.Course-info hr {
    border: 0;
    border-top: 1px solid #cccccc55;
    margin: 8px 0;
    width: 650px;
}

.morecourses {
    display: inline-block;
    font-size: 12px;
    padding: 10px;
    border: 1px solid #007BFF;
    background-color: #007BFF;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 350px;
}

.morecourses:hover {
    background-color: #4f9cee;
    color: #fff;
    text-decoration: none;
}


/* Media Queries for Responsive Design */
@media screen and (max-width: 1140px) {
    .Container {
        flex-direction: column;
        align-items: flex-start;
    }

    .left-column {
        width: 105%;
        padding: 30px;
        margin-left: 0;
    }

    .search-section h2 {
        margin-left: 0;
        text-align: center;
    }

    .Search-container {
        width: 90%;
    }

    .Search-container input[type="text"] {
        width: 80%;
    }

    .countriesSection h3,
    .Categories-section h3,
    .Uni-section h3,
    .Levels-section h3 {
        margin-left: 20px;
        text-align: center;
    }

    .Categories-section hr {
        margin-left: 20px;
        width: 100%;
    }

    .filter-list li {
        margin: 10px 0;
    }

    .filterlist {
        overflow-x: hidden; 
    }
    
    .expand-arrow {
        margin-right: 60px;
    }
    

    .right-column {
        width: 100%;
        padding: 30px;
        margin-top: 30px;
        margin-right: 0;
    }

    .Course-info hr {
        width: 100%;
    }

    .filter-list input[type="button"] {
        width: 65%; 
        padding: 10px; 
        margin: 5px -20px;
        font-size: 12px;
    }

    .filterlist input[type="button"] {
        width: 95%; 
        padding: 10px; 
        margin: 5px -20px;
        font-size: 12px;
    }

    .filter-list1 input[type="button"] {
        width: 65%; 
        padding: 10px; 
        margin: 5px -20px;
        font-size: 12px;
    }

}

@media screen and (max-width: 480px) {
    .left-column {
        padding: 20px;
    }

    .search-section h2 {
        font-size: 16px;
    }

    .Search-container input[type="text"] {
        width: 80%;
        margin-bottom: 10px;
    }

    .Search-container button {
        width: 15%;
        margin-bottom: 10px;
    }

    .countriesSection h3,
    .Categories-section h3,
    .Uni-section h3,
    .Levels-section h3 {
        font-size: 16px;
        position: relative; 
    }

    .countriesSection h3 .expand-arrow,
    .Categories-section h3 .expand-arrow,
    .Uni-section h3 .expand-arrow,
    .Levels-section h3 .expand-arrow {
        display: inline-block;
        font-size: 12px;
        color: #878889;
        margin-left: 10px;
        transition: transform 0.3s ease;
    }
    .countriesSection h3:hover .expand-arrow,
    .Categories-section h3:hover .expand-arrow,
    .Uni-section h3:hover .expand-arrow,
    .Levels-section h3:hover .expand-arrow {
         color: #878889;  
     }

    .filter-list {
        display: none; 
        padding-left: 0;
        font-size: 12px;
    }

    .filter-list.active {
        display: block; 
    }

    .filterlist {
        display: none; 
        padding-left: 0;
        font-size: 12px;
    }

    .filterlist.active {
        display: block; 
    }

    .filterlist1 {
        display: none; 
        padding-left: 0;
        font-size: 12px;
    }

    .filterlist1.active {
        display: block; 
    }

    .countriesSection h3.active .expand-arrow,
    .Categories-section h3.active .expand-arrow,
    .Uni-section h3.active .expand-arrow,
    .Levels-section h3.active .expand-arrow {
        transform: rotate(180deg); 
    }

    .Course {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .Date {
        width: 100%;
        margin-bottom: 10px;
    }

    .Course-info a {
        font-size: 16px;
    }

    .Course-info p {
        font-size: 12px;
    }

    .Course-info p1 {
        font-size: 10px;
    }

    .Course-info hr {
        width: 100%;
    }
}
